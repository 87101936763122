@import 'libs/assets/theme/mixins.scss';

.root {
  display: grid;
  gap: 0.5rem;
}

.vertical {
  grid-auto-flow: row;
}

.horizontal {
  display: flex;
  flex-direction: column;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  @include sm {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.buttonWrapper {
  flex: 1;
  width: 100%;
}

.label {
  text-align: center;
  width: auto;
}

.left {
  text-align: left;
}

.logIn {
  width: 100%;
}
