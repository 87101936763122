@import 'libs/assets/theme/mixins.scss';
@import './mixins.scss';

.root {
  display: flex;
  flex-direction: column;
  @include cardContent;

  > *:last-child {
    margin-bottom: 0;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
  &:empty {
    display: none;
  }
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
  @include below-lg {
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.productImage {
  margin-right: 1rem;
}

.body {
  display: flex;
  flex-direction: column;
  grid-auto-flow: row;
  @include rowGap(0.5rem);
  margin-bottom: 1rem;
}
.ubermeta {
  grid-gap: 1.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}
.meta {
  /**
    I use grid here 'cause @mui Divider component doesn't work with flexbox (height is 0)
  */
  display: grid;
  width: fit-content;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
}

.topAdornemnts {
  display: flex;
  margin-bottom: 0.5rem;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
  // @include columnGap(0.75rem);
}

.properties {
  display: flex;
  @include columnGap(1rem);
  &.shippingInfo {
    flex-wrap: wrap;
    @include rowGap(0.25rem);
  }
}

.values {
  margin-bottom: 1rem;
}

.propertiesElementsAligned {
  align-self: center;
  flex-shrink: 0;
}

.sizeCounterDeadProduct {
  flex-grow: 1;
}

.deadProduct {
  padding-bottom: 3.5rem !important;
  &Body {
    display: flex;
    gap: 0.5rem;
  }
}

.shippingValue {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 0;
}
