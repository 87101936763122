.stretchHorizontal {
  flex: 1;
}

.stretchVertical {
  height: 100%;
}

.readonlyWrapper {
  padding: 0;
}

.readonlyInputRoot {
  border-radius: 0.25rem !important; // FIXME: "sx" property of box overrides this styles without important
  height: 100%;
}
