@import 'libs/assets/theme/mixins.scss';

.values {
  display: grid;
  gap: 1rem;
  @include below-lg {
    gap: 0.75rem;
  }
  @include below-sm {
    gap: 0.5rem;
  }
  height: fit-content;
  min-width: 0;
}

.vertical {
  grid-auto-flow: row;
}

.horizontal {
  grid-auto-flow: column;
  align-items: flex-end;
  width: fit-content;
  &.resetFixedHeight {
    height: auto;
  }
}
