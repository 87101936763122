.label {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.25rem;
  place-items: center;
  min-width: 0;
  overflow: hidden;
}

.labels {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  place-items: center flex-start;
  height: fit-content;
  margin-top: auto;

  svg {
    margin: 0;
  }
}

.titleRoot {
  min-width: 0;
}

.horizontal {
  display: flex;
}
