@import 'assets/theme/mixins.scss';
@import 'libs/assets/theme/elevations.scss';

.cardWrapper,
.cancelledOfferItemCardWrapper,
.unknownOrderItemCardWrapper {
  position: relative;
  width: 100%;
  :global .MuiPaper-root {
    opacity: 0.5;
  }
}

.cancelledOfferItemCardWrapper {
  height: 12.75rem;
  @include below-sm {
    height: 18.75rem;
  }
  :global .MuiPaper-root {
    padding-left: 2rem;
    padding-top: 1.25rem;
    height: 10.5rem;
    @include below-sm {
      height: 16.5rem;
    }
  }
}

.unknownOrderItemCardWrapper {
  height: 18.5rem;
  @include sm {
    height: 12.75rem;
  }

  :global .MuiPaper-root {
    padding-top: 2.5rem;
    height: 18.5rem;
    @include sm {
      padding-top: 0;
      height: 11.5rem;
    }
    @include md {
      padding-top: 0;
      height: 12.75rem;
    }
    @include lg {
      padding-top: 1.5rem;
    }
  }
}

.unknownOrderItemProperties {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.checkbox {
  align-self: center;
  margin-right: 0.5rem;
}

.deadProductItemUpperOverlay,
.cancelledOfferItemUpperOverlay,
.unknownOrderItemOverlay {
  position: absolute;
  top: 0;
  left: 0;
  background: $white;
  height: 7.5rem; /* 120px rest of the product card height in tablet and desktop */
  @include below-sm {
    height: 13.5rem; /* rest of the product card height for mobile */
  }
  width: 100%;
  opacity: 0;
  @include dropShadow1;
}

.cancelledOfferItemUpperOverlay {
  height: 10.5rem; /* 168px rest of the product card height in tablet and desktop */
  @include below-sm {
    height: 16.5rem; /* 264px rest of the product card height for mobile */
  }
}
