@import 'libs/assets/theme/mixins.scss';

.replacementArticle {
  display: flex;
  align-items: center;
}

.replacementArticleText {
  font-weight: 700;
  margin-left: 0.5rem;
}

.arrowLeftRight {
  color: $darkGray;
  width: 1.25rem;
  height: 1.25rem;
}
