@import 'assets/theme/mixins.scss';
@import './mixins.scss';

.bottomElement {
  display: grid;
  grid-auto-flow: row;
  gap: 0.5rem;
  order: 100;

  > *:empty {
    display: none;
  }
}

.horizontal {
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  gap: 1.25rem;
}

.vertical {
  grid-auto-flow: row;
  align-items: flex-end;
}
