.subActions {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  order: 200;
  gap: 1rem;

  &.compact {
    margin-top: 0.25rem;
  }
}
.transferIdsButton {
  margin-left: auto;
}
