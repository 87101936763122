.singleLineTitle {
  overflow: hidden;
  a {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      white-space: nowrap;
      display: inline;
    }
  }
}

.bottomTablet {
  padding-top: 0.5rem;
  gap: 1.25rem;
}
