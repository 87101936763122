@import 'libs/assets/theme/coreVariablesV2.scss';

.button {
  width: 1.875rem;
  height: 1.875rem;
  border: 0.0625rem solid $lightGray;
  border-radius: 0.25rem;
  padding: 0;
  min-width: 1.875rem;
  box-sizing: content-box;
}
