.statuses {
  display: grid;
  width: fit-content;
  min-width: 0;
}

.horizontal {
  grid-gap: 1rem;
  grid-auto-flow: column;
}

.vertical {
  grid-gap: 0.5rem;
  grid-auto-flow: row;
}
