.root {
  padding: 1rem;
  padding-bottom: 0.5rem;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.25rem;
  width: 22.75rem;
  box-sizing: border-box;
}

.menuList {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.25rem;
  padding: 0;
  max-height: 13.5rem;
  overflow: auto;
}

.menuItem {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}

.menuListMobile {
  padding: 0.5rem 0;
}

.menuItemMobile {
  padding: 0.5rem 1rem;
}

.paperMobile {
  width: 100% !important;
  padding: 0 !important;
}
