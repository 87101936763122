@import 'libs/assets/theme/mixins.scss';

.container {
  @include ellipsis;
  line-height: 1;
  width: 100%;
}

.underline {
  border-bottom: 0.0625rem solid;
  cursor: pointer;
}
