@import 'assets/theme/mixins.scss';
@import './mixins.scss';

.actions {
  display: flex;
  justify-content: flex-end;

  @include sm {
    display: grid;
    grid-gap: 0.5rem;
    height: fit-content;
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
  @include below-lg {
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}

.vertical {
  grid-auto-flow: column;
  align-self: flex-end;
  justify-content: flex-end;
}

.horizontal {
  grid-auto-flow: column;
}
