.paper {
  width: 18.7rem;
  grid-gap: 0.5rem;
}

.menuItem {
  height: 2.25rem;
}

.menuItemMobile {
  height: 3rem;
}

.mobileSearchField {
  padding: 1rem;
  padding-bottom: 0;
  box-sizing: border-box;
}

.footerMobile {
  padding: 0 1rem;
  padding-bottom: 0.5rem;
}
