@import 'libs/assets/theme/mixins.scss';

.lightGrayFlag,
.darkGrayFlag {
  align-items: center;
  border-radius: 0.125rem;
  height: 1.25rem;
  width: fit-content;
}
.lightGrayFlag {
  background-color: $lightGray5K;
  display: flex;
  padding: 0 0.5rem;
}

.darkGrayFlag {
  background-color: $darkGray;
  display: inline-flex;
  :global .MuiTypography-root {
    color: $white;
  }
  padding: 0.125rem 0.25rem;
}

.sapPromotionalProductCardWrapper {
  :global .MuiPaper-root {
    padding-left: 2rem;
  }
}
