@import 'libs/assets/theme/mixins.scss';

.price {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.typography {
  display: inline-block;
  @include ellipsis;
}
