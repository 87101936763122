@import 'libs/assets/theme/mixins.scss';

.cancelled,
.return {
  background-color: $darkGray55K;
}

.archived,
.credit,
.inProgress,
.invoiced {
  background-color: $darkGray;
}

.inArrears {
  background-color: $red100pct;
}

.inDelivery {
  background-color: $greenLight;
}

.partialCollection,
.partialDelivery,
.partiallyReadyForPickUp {
  background-color: $orange;
}

.pickedUp,
.readyForPickUp,
.shipped {
  background-color: $green;
}

.state {
  border-radius: 0.125rem;
  color: $white;
  display: inline-flex;
  padding: 0.125rem 0.25rem;
  width: fit-content;
}
