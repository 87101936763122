@import 'libs/assets/theme/mixins.scss';

.scrollbar {
  @include scrollbar;
}

.popoverMobile {
  width: 100%;
  padding: 0;
  max-width: unset;
  max-height: unset;
  border-radius: 0;
  top: unset !important; // reset default styles set via style attr
  left: 0 !important; // reset default styles set via style attr
  bottom: 0 !important; // reset default styles set via style attr
  display: block;
  box-sizing: border-box;
}
