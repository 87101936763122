@import 'libs/assets/theme/mixins.scss';

.state {
  border-radius: 0.125rem;
  background-color: $lightGray8K;
  display: inline-flex;
  padding: 0.125rem 0.25rem;
  width: fit-content;
  gap: 0.25rem;
  height: 1.5rem;
  align-items: center;
}

.orangeShorter {
  height: 1rem;
  background-color: $orange;
}

.white {
  color: $white;
}
